import React from "react";
import { graphql } from "gatsby";
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";
import { GatsbyImage } from "gatsby-plugin-image";
import { Container, Row, Col } from "react-bootstrap";
import Seo from "../components/meta/Seo";
import Layout from "../components/common/Layout";
import Anchor from "../components/elements/Anchor";

import { constructActiveDoc } from "../utilities";

const Collection = ({ data }) => {
  if (!data) return null;

  let numberOfCols;

  const { number_of_columns } = data.collection.data;
  if (number_of_columns) {
    numberOfCols = number_of_columns;
  } else {
    numberOfCols = 3;
  }

  const { prismicSettings: settings, collection } = data;
  const products = data.collectionProducts.nodes;

  return (
    <>
      <Seo page={collection} />
      <Layout
        settings={settings}
        activeDocMeta={constructActiveDoc(collection)}
      >
        <Container>
          <h1 className="mb-5 py-5">{collection.data.title}</h1>
          {/* sm={1} md={2} lg={3} xl={4} xxl={5} */}
          <Row
            xs={1}
            sm={1}
            md={2}
            lg={3}
            xl={numberOfCols >= 4 && 4}
            xxl={numberOfCols >= 5 && numberOfCols}
          >
            {products.map((product) => {
              return (
                <Col key={product.id} className="mb-4">
                  <Anchor href={`${product.url}`}>
                    <div className="product-card gap-2 p-4">
                      <div className="m-auto product-image-container">
                        <GatsbyImage
                          image={
                            product.data.product_main_image[0].main_image
                              .gatsbyImageData
                          }
                          alt={
                            product.data.product_main_image[0].main_image.alt ||
                            "product image"
                          }
                        />
                      </div>
                      <h2 className="product-name">{product.data.title}</h2>
                      {/* <div className="product-name-container">
                      </div> */}
                    </div>
                  </Anchor>
                </Col>
              );
            })}
          </Row>
        </Container>
      </Layout>
    </>
  );
};

export const query = graphql`
  query collectionQuery($lang: String, $id: String, $collectionSlug: [String]) {
    prismicSettings(lang: { eq: $lang }) {
      ...prismicSettingsFragment
    }
    collection: prismicCollection(lang: { eq: $lang }, id: { eq: $id }) {
      _previewable
      alternate_languages {
        uid
        type
        lang
      }
      lang
      type
      url
      id
      uid
      tags
      data {
        title
        meta_title
        meta_description
        number_of_columns
        meta_image {
          alt
          dimensions {
            height
            width
          }
          url
        }
      }
    }
    collectionProducts: allPrismicProduct(
      filter: { tags: { in: $collectionSlug } }
    ) {
      nodes {
        id
        url
        tags
        data {
          product_main_image {
            main_image {
              gatsbyImageData(placeholder: BLURRED)
              alt
            }
          }
          title
        }
      }
    }
  }
`;

export default withPrismicPreview(Collection);
